/* eslint sonarjs/cognitive-complexity: 1 */
import {
  type AppliedReduction,
  type Price as BapiPrice,
  type CentAmount,
  getFirstAttributeValue,
  getLatestCategory,
  getLowestPrice,
  getOriginalPrice,
  getPrice,
  type Order,
  type Price,
  type Product,
  type Variant,
} from '@scayle/storefront-nuxt'
import type { NuxtApp } from 'nuxt/app'
// magic imports don't seem to work when called from serverside tracking plugin :/
import type { RouteLocationNormalizedLoaded } from '#vue-router'
import { useGlassesLenses } from '~/composables/pdp/useGlassesLenses'
import type {
  ConfiguratorState,
  ConfiguratorStateValue,
} from '~/composables/useRxConfiguratorState'
import { capitalize, getProductCategory } from '../../../utils/product'
import {
  categoryTrackingAttributes,
  lensTypeReferenceKeyToTrackingValue,
  staticCategoryTrackingValues,
  tintingTypeReferenceKeyToTrackingValue,
} from './trackingMaps'
import { filterLensQualityIndexItems } from '~/utils/rx'
import { getProductMetaTitle } from '~/utils/seo'
import {
  isAdditionalTrackingEvent,
  isEcommerceTrackingEvent,
  isEcommerceTrackingEventWithPromotion,
  isProductImpressionsData,
  isPromotionTrackingEvent,
} from '~/utils/tracking'
import type { ProductWithPrice } from '~/types/tracking'

const toFloat = (price: number) => price / 100

const isBapiPrice = (price: any): price is BapiPrice =>
  price && 'withTax' in price

export const getAppliedReductionsByCategory = (
  price: ObjectWith<{ appliedReductions: AppliedReduction[] }>,
  category: AppliedReduction['category'],
): AppliedReduction[] => {
  return (
    price?.appliedReductions?.filter((reduction) => {
      return reduction.category === category
    }) ?? []
  )
}

export const getFloatedReducedPriceForCategoryOrNull = (
  price: ObjectWith<{ appliedReductions: AppliedReduction[] }>,
  type: AppliedReduction['category'],
) => {
  if (!price?.appliedReductions) {
    return 0.0
  }

  const appliedReductions = getAppliedReductionsByCategory(price, type)
  const appliedAbsoluteWithTax = appliedReductions?.reduce(
    (res, item) => item.amount.absoluteWithTax + res,
    0,
  )

  return toFloat(appliedAbsoluteWithTax) || 0.0
}

export const getConcatedVoucherCode = (
  price: ObjectWith<{ appliedReductions: AppliedReduction[] }>,
) => {
  const codes = getAppliedReductionsByCategory(price, 'voucher')
    .map((data) => ('code' in data ? `${data.code}` : undefined))
    .filter(Boolean)

  if (codes.length) {
    return [...new Set(codes)].join(' | ')
  }
}

const hasCode = (
  code: string | undefined,
  appliedReductions: Array<{ code?: string }>,
) => {
  if (!code) {
    return false
  }
  return appliedReductions.some(
    (appliedReduction) => appliedReduction.code === code,
  )
}

export const getGlobalVouchers = (items: FimOrderItem[]) => {
  // filter all free items as there are no reductions applied
  const prices =
    items?.map((item) => item.price)?.filter((price) => price.withTax > 0) ?? []

  // get appliedReductions
  const appliedReductions = prices.map(
    (price) =>
      price?.appliedReductions?.filter((reduction) => {
        return reduction.category === 'voucher'
      }) ?? [],
  )

  // get all voucher codes
  const codes = [
    ...new Set(
      appliedReductions.flatMap((items) => items.map((item) => item.code)),
    ),
  ]

  // get voucher codes which are applied to all items
  const globalCodes = codes.filter((code) =>
    appliedReductions.every((list) => hasCode(code, list)),
  )

  // get all globally applied reductions
  const globalAppliedReductions = appliedReductions.flatMap((list) =>
    list.filter((item) => globalCodes.includes(item.code)),
  )

  return Object.values(
    globalAppliedReductions.reduce(
      (groups, item) => {
        const code: string = item.code ?? '-'
        const current = groups?.[code]

        return {
          ...groups,
          [code]: {
            ...item,
            amount: {
              ...item.amount,
              absoluteWithTax: (item.amount.absoluteWithTax +
                (current?.amount?.absoluteWithTax ?? 0)) as CentAmount,
            },
          },
        }
      },
      {} as Record<string, AppliedReduction>,
    ),
  )
}

/**
 * Absolute vouchers are not added to the applied reductions per item.
 * They are only available in the vouchers object on the order
 * @param order order object
 * @param items grouped fim orderitems
 * @returns items with absolute vouchers applied
 */
export const applyAbsoluteVouchers = (order: Order, items: FimOrderItem[]) => {
  const result = [...(items || [])]

  const addReduction = (
    key: string,
    voucher: Exclude<Order['vouchers'], undefined>[number],
  ) => {
    // Find item
    const index = result.findIndex((item) => item.key === key)
    if (index !== -1) {
      // Adjust item price
      const price = result[index].price
      const vat = price.tax?.vat?.rate ?? 0

      // Add applied reductions for global voucher
      price.appliedReductions = [
        ...(price?.appliedReductions ?? []),
        {
          amount: {
            absoluteWithTax: voucher.value,
            relative: Math.floor((voucher.value / price.withTax) * 100) / 100,
          },
          category: 'voucher',
          code: voucher.code,
          type: voucher.type,
        },
      ]

      // Adjust price as the absolute voucher is not subsctracted
      price.withTax -= voucher.value
      // Calculate net price based on reduced gross price
      price.withoutTax = Math.floor(price.withTax / (1 + vat))
      // Adjust vat tax if available
      if (vat) {
        price.tax.vat.amount = price.withTax - price.withoutTax
      }

      result[index].price = price
    }
  }

  const vouchers = (order.vouchers ?? []).filter(
    (voucher) => voucher.type === 'absolute',
  )

  for (const voucher of vouchers) {
    for (const applicableItem of voucher?.applicableItems ?? []) {
      // Absolute vouchers are only applied to one item in the basket
      if (applicableItem.isApplied && applicableItem.key) {
        addReduction(applicableItem.key, voucher)
      }
    }
  }

  return result
}

const convertPrice = (price: Price): TrackingPrice => ({
  price_gross: toFloat(price.withTax),
  price: toFloat(price.withoutTax),
  sale_discount: getFloatedReducedPriceForCategoryOrNull(price, 'sale'),
  campaign_discount: getFloatedReducedPriceForCategoryOrNull(price, 'campaign'),
  original_price: toFloat(
    price?.appliedReductions?.length ? getOriginalPrice(price) : price.withTax,
  ),
})

/**
 * @todo refactor
 * there are currently too many different ways to get the price
 */
const getTrackingPrice = (
  product: ProductWithPrice,
  payload: CartViewPayload | ProductActionData | ProductListData,
  options?: MapToTrackingPayloadOptions,
  $currentShop?: NuxtApp['$currentShop'],
): TrackingPrice | undefined => {
  if (product.formattedPrice) {
    return convertPrice(product.formattedPrice)
  }

  let price
  if (product.priceRange && product.priceRange.min) {
    price = product.priceRange.min
  } else if ('price' in payload) {
    price = payload.price.total
  } else if (options?.variant) {
    price = getPrice(options.variant)
  } else if (product.variants) {
    price = getLowestPrice(product.variants || [])
  }

  // option to allow the price to be overridden since it's possible that product.priceRange.min(default price) is not the correct tracked price
  if (typeof options?.overridePrice === 'function') {
    const overriddenPrice = options.overridePrice(product, options?.variant)
    price = overriddenPrice ?? price
  }
  if (options?.overridePrice && isBapiPrice(options?.overridePrice)) {
    price = options.overridePrice
  }

  // RX product with configuration values
  const hasConfiguration =
    'customData' in payload && !!payload.customData?.configurationItems

  if (isCorrectionalGlasses(product) && hasConfiguration) {
    price = getRXFullPrice(payload)
  } else if (isCorrectionalGlasses(product)) {
    const { lensesVariants } = useGlassesLenses()
    const lenses = filterLensQualityIndexItems<Variant>(
      lensesVariants,
      product,
      $currentShop,
    )
    price = combinePricesWithCheapestLens(price, lenses)
  }

  return convertPrice(price)
}

const getSubscriptionFromCustomData = (
  payloadOrOptions: any,
): SubscriptionDefinition | undefined =>
  'customData' in payloadOrOptions &&
  payloadOrOptions.customData?.subscriptionDefinition
    ? payloadOrOptions.customData.subscriptionDefinition
    : undefined

export const getTrackingSubscription = (
  payload: CartViewPayload | ProductActionData | ProductListData,
  options: MapToTrackingPayloadOptions,
): TrackingSubscription => {
  const subscriptionDefinition = getSubscriptionFromCustomData({
    ...payload,
    ...options,
    ...('options' in payload && (payload as any).options),
  })

  return {
    subscription_model: !!subscriptionDefinition,
    subscription_frequency:
      subscriptionDefinition && subscriptionDefinition.subscriptionInterval
        ? parseInt(subscriptionDefinition.subscriptionInterval, 10)
        : '-',
  }
}

export const mapProductToTrackingPayload = (
  payload: CartViewPayload | ProductActionData | ProductListData,
  options: MapToTrackingPayloadOptions,
): ProductInfo => {
  const { context } = options || {}
  const { product } = payload

  const $currentShop = unref(options.$currentShop || context?.$currentShop)
  const price = getTrackingPrice(product, payload, options, $currentShop)
  if (!price) return {} as ProductInfo

  let itemCategories = {}

  if (options?.$i18n) {
    itemCategories = getProductCategoryTrackingValues(
      product,
      options.$i18n,
      (options?.variant || product.variants?.[0]) ?? undefined,
    )
  } else {
    console.error(
      'TRACKING ERROR: context (i18n) not passed to mapProductToTrackingPayload',
    )
  }

  // Subscription
  const subscription = getTrackingSubscription(payload, options)

  return {
    item_id: product.id.toString(),
    item_name: getFirstAttributeValue(product.attributes, 'name')!.label,
    item_brand:
      getFirstAttributeValue(product.attributes, 'brand')?.label ?? '',
    item_brand_id:
      getFirstAttributeValue(product.attributes, 'brand')?.id?.toString() ?? '',
    ...subscription,
    ...itemCategories,
    ...price,
  }
}

const mapAdditionalInfo = (
  data: ProductActionData | ProductListData | ProductViewData,
): Omit<AdditionalInfo, 'item_category'> | ViewInfo => {
  const { product, list } = data

  // Variant can be passed within options to override one on product or add
  // missing variant on Contact Lenses
  const variant = data?.variant
    ? data.variant.id.toString()
    : product.variants
      ? product.variants![0].id.toString()
      : ''

  return {
    item_category_id:
      getLatestCategory(product.categories)?.categoryId.toString() || '',
    item_variant: variant,
    item_list_name: list?.name || '',
    item_list_id: `${list?.id || ''}`,
    ...(typeof list?.index !== 'undefined' && { index: list?.index }),
    ...('quantity' in data && { quantity: data.quantity }),
    ...(product.isSoldOut && { sold_out: true }),
  }
}

// Adds promotion data to cookies to be used for future tracking events
const addPromotionToCookies = (payload: PromotionData): void => {
  const cookie = useJsonCookie<PromotionData>('current-promotion')
  cookie.value = payload
}

/**
 * Updates the listing data also adding the sibling products to the cookies
 * This is used for ECommerce events and works on all product pages
 */
const updateListingDataCookies = (item_id_list: string[]): void => {
  const cookie = useJsonCookie<{ [key: string]: ListingData }>('listing-data')
  if (!cookie.value) return

  const storedProductIds = Object.keys(cookie.value)

  const matchingIds = item_id_list.filter((id) =>
    storedProductIds.includes(id.toString()),
  )

  if (matchingIds.length > 0 && matchingIds.length !== item_id_list.length) {
    const parentProduct = cookie.value[matchingIds[0]]
    addListingDataToCookies(
      item_id_list,
      parentProduct.item_list_id ?? '',
      parentProduct.item_list_name ?? '',
    )
  }
}

const addListingDataToCookies = (
  item_id_list: string[],
  item_list_id: string,
  item_list_name: string,
): void => {
  const listingData: { [key: string]: ListingData } = {}
  item_id_list.forEach((id) => {
    listingData[id] = { item_list_id, item_list_name }
  })

  const cookie = useJsonCookie<{ [key: string]: ListingData }>('listing-data')
  cookie.value = {
    ...cookie.value,
    ...listingData,
  }
}

// Mapping cookie for item/promotion
// is going to be used for the events on MULTIPLE_PROMOTION_EVENTS
const promotionMappingForCheckout = (product: Product): void => {
  const promotion = useJsonCookie<PromotionData>('current-promotion').value
  if (promotion?.promotion_id) {
    const cookie = useJsonCookie<{ [key: string]: PromotionData }>(
      'basket-promotion-mapping',
    )
    cookie.value = { ...cookie.value, [product.id]: { ...promotion } }
  }
}

const getTotalPriceInfo = (
  items: {
    quantity: number
    campaign_discount: number
    sale_discount: number
    price_gross: number
    price: number
  }[],
) => {
  let total = {
    total_campaign_reduction_with_tax: 0.0,
    total_sale_reduction_with_tax: 0.0,
    total_with_tax: 0.0,
    total_without_tax: 0.0,
  }
  items.forEach((item) => {
    total = {
      total_campaign_reduction_with_tax:
        total.total_campaign_reduction_with_tax +
        item.campaign_discount * item.quantity,
      total_sale_reduction_with_tax:
        total.total_sale_reduction_with_tax +
        item.sale_discount * item.quantity,
      total_with_tax: Number(
        (total.total_with_tax + item.price_gross * item.quantity).toFixed(2),
      ),
      total_without_tax: Number(
        (total.total_without_tax + item.price * item.quantity).toFixed(2),
      ),
    }
  })

  return total
}

const sumUpNumericItemProp = <T extends 'price' | 'price_gross'>(
  items: ObjectWith<{ [key in T]: number }>[],
  prop: T,
) =>
  items.reduce(
    (prevVal, curVal) => prevVal + curVal[prop] * (curVal.quantity ?? 1),
    0,
  )

// @todo refactor
export const mapTrackingDataForEvent = (
  event: TrackingEvent,
  payload: TrackingPayload,
  options: MapToTrackingPayloadOptions,
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  const { context } = options || {}
  const $currentShop = unref(options.$currentShop || context?.$currentShop)
  let data = {}

  if ('FielmannBasic_EC_ItemView' === event) {
    const { product } = payload as any
    const listProductIds = [product.id]

    const siblings = product?.siblings || []
    siblings.forEach((sibling: any) => {
      listProductIds.push(sibling.id)
    })

    updateListingDataCookies(listProductIds)
  }

  if ('FielmannBasic_EC_SelectItem' === event) {
    const { product, list } = payload as any
    addListingDataToCookies([product.id], list?.id, list?.name)
  }

  if ('FielmannBasic_EC_PromotionClick' === event) {
    addPromotionToCookies(payload as PromotionData)
  }
  if ('FielmannBasic_EC_AddToCart' === event) {
    const { product } = payload as ProductActionData
    promotionMappingForCheckout(product)
  }
  if (isPromotionTrackingEvent(event)) {
    data = {
      ecommerce: payload,
    }
  } else if (
    isAdditionalTrackingEvent(event) &&
    isProductImpressionsData(payload)
  ) {
    const items = payload.items.map((item) => ({
      ...mapProductToTrackingPayload(item, options),
      ...mapAdditionalInfo(item),
    }))

    // @ts-expect-error
    data.items = items
    const totalPrice = getTotalPriceInfo(
      items.map((item) => ({
        price: item.price,
        quantity: item.quantity ? item.quantity : 1,
        price_gross: item.price_gross,
        campaign_discount: item.campaign_discount,
        sale_discount: item.sale_discount,
      })),
    )

    data = {
      ...data,
      ...totalPrice,
    }
  } else if (isProductImpressionsData(payload)) {
    const items = payload.items.map((item) => ({
      ...mapProductToTrackingPayload(item, options),
      ...mapAdditionalInfo(item),
    }))

    data = {
      ecommerce: {
        currency: $currentShop?.currency || 'EUR',
        items,
        value: +sumUpNumericItemProp(items, 'price').toFixed(2),
        value_gross: +sumUpNumericItemProp(items, 'price_gross').toFixed(2),
      },
    }
    addSearchTrackingData(payload, data)
  } else if ('product' in payload) {
    data = {
      ecommerce: {
        currency: $currentShop?.currency || 'EUR',
        items: [
          {
            ...mapProductToTrackingPayload(
              payload,
              'variant' in payload
                ? { variant: payload.variant, ...options }
                : options,
            ),
            ...mapAdditionalInfo(payload),
          },
        ],
      },
    }
    addSearchTrackingData(payload, data)
  } else {
    data = {
      ...payload,
    }
  }

  // Adds promotion data to individual items
  if (isMultiplePromotionEvents(event)) {
    const basket = useJsonCookie<{ [key: string]: PromotionData }>(
      'basket-promotion-mapping',
    )

    // @ts-expect-error
    const items = data.ecommerce.items.map((item) => {
      const promotion = basket.value ? basket.value[item.item_id] : undefined
      return { ...item, ...promotion }
    })
    // @ts-expect-error
    data.ecommerce.items = items
  }

  // Add listing data (item_list_name, item_list_id) to ECommerce events
  if (isListAttributionTrackingEvent(event)) {
    const listing = useJsonCookie<{ [key: string]: ListingData }>(
      'listing-data',
    )

    const items =
      // @ts-expect-error
      data?.ecommerce?.items?.map((item) => {
        const selectedList = listing?.value?.[item.item_id]
        item.item_list_name ||= selectedList?.item_list_name ?? ''
        item.item_list_id ||= selectedList?.item_list_id ?? ''
        return item
      }) ?? []

    // @ts-expect-error
    data.ecommerce.items = items
  }

  // Adds promotion data to ECommerce events
  if (isEcommerceTrackingEventWithPromotion(event)) {
    const promotion = useJsonCookie<PromotionData>('current-promotion').value

    // @ts-expect-error
    const items = data.ecommerce.items.map((item) => ({
      ...item,
      ...promotion,
    }))

    // @ts-expect-error
    data.ecommerce.items = items
  }

  return {
    event,
    ...data,
    ...((isEcommerceTrackingEvent(event) ||
      event.toLowerCase().startsWith('fielmann')) && {
      ...mapFimMetaTrackingData(payload, options),
      ...mapFimUserTrackingData(payload, options),
    }),
  }
}

export const getPageType = (
  route: RouteLocationNormalizedLoaded,
  fallbackPageType = 'other',
) => {
  const match = route?.matched?.[0]
  const component: Record<string, unknown> = {
    ...(match?.components?.default ?? {}),
  }

  // @ts-expect-error meta is not available on {}
  return component?.options?.meta?.pageType ?? (fallbackPageType || 'other')
}

export const mapFimMetaTrackingData = (
  payload: FimTrackingPayload | TrackingPayload,
  options?: MapToTrackingPayloadOptions,
): { meta: FimMetaTrackingData } => {
  const { context } = options || {}
  const runtimeConfig = useRuntimeConfig()
  const route = options?.route || context?.route
  const $currentShop = toValue(options?.$currentShop || context?.$currentShop)
  const $i18n = options?.$i18n || context?.$i18n
  const pageState = toValue(options?.pageState)
  const applicationId =
    'meta' in payload
      ? (payload?.meta?.applicationId ?? $i18n.t('tracking.meta.applicationId'))
      : $i18n.t('tracking.meta.applicationId')

  const pageType =
    // @ts-expect-error page_type is not available on payload
    payload.page_type === 'error'
      ? 'error'
      : (options?.overridePageType ??
        getPageType(toValue(route), pageState?.pageType ?? 'other'))
  const locale = $currentShop?.locale
  const language = getIsoLanguage(locale)
  const country = getIsoCountry(locale).toLowerCase()
  const virtualPagePath = toValue(route)?.path

  // @ts-expect-error title is not available on payload
  let virtualPageTitle = payload.title

  if (!virtualPageTitle && pageType === 'product' && 'product' in payload) {
    virtualPageTitle = getProductMetaTitle(
      payload.product,
      options?.variant || undefined,
      options?.context,
    )
  } else if (!virtualPageTitle && typeof document !== 'undefined') {
    virtualPageTitle = document.title
  }

  return {
    meta: {
      applicationVersion: runtimeConfig.public.applicationVersion,
      applicationId,
      pageType,
      country,
      language,
      virtualPagePath,
      virtualPageTitle,
      ep_bot_traffic: options?.isBotTraffic ?? false,
    },
  }
}

export const mapFimUserTrackingData = (
  payload: FimTrackingPayload | TrackingPayload,
  options?: MapToTrackingPayloadOptions,
): { user: FimUserTrackingData } => {
  const { user, isLoggedIn } = options || {}
  const pageState = unref(options?.pageState)

  let email = user?.email ?? ''
  if (pageState?.userEmail) {
    email = pageState.userEmail
  }
  let loginStatus = isLoggedIn ? 'logged in' : 'logged out'
  let loginType = isLoggedIn ? 'Account' : 'Guest'

  if ('user' in payload && payload?.user?.email) {
    email = payload.user.email
  }

  if ('user' in payload && 'loginStatus' in payload.user) {
    loginStatus = payload?.user?.loginStatus ?? loginStatus
    loginType = payload?.user?.loginType ?? loginType
  }

  return {
    user: {
      loginStatus,
      loginType,
      email,
    },
  }
}

export const mapFimErrorTrackingData = (payload: PageViewData) => {
  return {
    ...(payload.error ? { error: payload.error } : {}),
  }
}

const getTranslatedString = (
  i18n: NuxtApp['$i18n'],
  value: string,
  translationLocation: string,
) => {
  const translationPath = `${translationLocation}.${value.toLowerCase()}`
  const translationResult = i18n.t(translationPath)
  return translationResult === translationPath
    ? capitalize(value)
    : translationResult
}

export const getProductCategoryTrackingValues = (
  product: Product,
  i18n: NuxtApp['$i18n'],
  variant?: Variant,
) => {
  let mappedTrackingValues = Array.from({ length: 5 }, () => '')
  const productCategory = getProductCategory(product)
  if (productCategory) {
    const translationLocation = 'tracking.product_attributes'
    const staticTrackingValues = staticCategoryTrackingValues[
      productCategory
    ].map((value) => getTranslatedString(i18n, value, translationLocation))
    const trackingAttributes = categoryTrackingAttributes[productCategory]
    const productTrackingValues = trackingAttributes.map((attribute) => {
      const attributeLabel =
        attribute === 'packingType' && variant
          ? (getFirstAttributeValue(variant.attributes, attribute)?.value ?? '')
          : (getFirstAttributeValue(product.attributes, attribute)?.value ?? '')
      return getTranslatedString(i18n, attributeLabel, translationLocation)
    })
    mappedTrackingValues = [...staticTrackingValues, ...productTrackingValues]
  }
  return {
    item_category: mappedTrackingValues[0] ?? '',
    item_category2: mappedTrackingValues[1] ?? '',
    item_category3: mappedTrackingValues[2] ?? '',
    item_category4: mappedTrackingValues[3] ?? '',
    item_category5: mappedTrackingValues[4] ?? '',
  }
}

export const mapFimProductTrackingData = (
  payload: FimTrackingPayload,
  options: MapToTrackingPayloadOptions,
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  const { context } = options || {}
  const $currentShop = unref(options.$currentShop || context?.$currentShop)
  const $i18n = options.$i18n || context?.$i18n

  if ('productDetails' in payload && payload.productDetails) {
    const product = unref(payload.productDetails)
    const variant = unref(options.variant) || product.variants?.[0]

    const productName = getProductName(product)

    const wareHouseId = variant.stock?.warehouseId?.toString() ?? '0'
    const sellableWithoutStock =
      variant?.stock?.isSellableWithoutStock && variant?.stock?.quantity === 0

    const sellableWithoutStockTotalTime =
      options.shopCustomData?.deliveryEstimates?.sellableWithoutStockTotalTime
    const wareHouseEstimates =
      options.shopCustomData?.deliveryEstimates?.warehouse?.[wareHouseId]

    let deliveryInfo = sellableWithoutStock
      ? $i18n.t('pdp.delivery_forecast_out_of_stock')
      : $i18n.t('pdp.delivery_forecast_in_stock')

    if (sellableWithoutStock && sellableWithoutStockTotalTime) {
      deliveryInfo = $i18n.t('pdp.delivery_in', {
        value: sellableWithoutStockTotalTime,
      })
    } else if (!sellableWithoutStock && wareHouseEstimates) {
      deliveryInfo = $i18n.t('pdp.delivery_in', {
        value: wareHouseEstimates,
      })
    }

    const colorVariant = [
      getFirstAttributeValue(product?.attributes, 'frameColor'),
      getFirstAttributeValue(product?.attributes, 'lensBaseColor'),
    ]
      .filter(Boolean)
      .map((color) => color?.label)
      .join(' / ')

    const itemCategories = getProductCategoryTrackingValues(
      product,
      $i18n,
      variant,
    )

    return {
      productDetails: {
        name: productName,
        id: productName + '-' + product?.id,
        sku: variant?.referenceKey ?? '',
        brand: getFirstAttributeValue(product.attributes, 'brand')?.label ?? '',
        ...itemCategories,
        price: ((product?.priceRange?.max?.withTax ?? 0) / 100).toFixed(2),
        variant: colorVariant,
        currency:
          product?.priceRange?.max?.currencyCode ??
          ($currentShop?.currency || 'EUR'),
        availability: {
          deliveryDays: /([\d-]+)/.exec(deliveryInfo)?.[1] ?? '',
          deliveryText: deliveryInfo,
        },
      },
    }
  }

  return {}
}

export const mapFimElementTrackingData = (
  payload: FimTrackingPayload,
  _options: MapToTrackingPayloadOptions,
) => {
  if (!('element' in payload)) {
    return
  }

  if (payload.element instanceof HTMLElement) {
    const id =
      payload?.element?.getAttribute('data-tracking-id') ||
      payload?.element?.id ||
      payload?.element?.getAttribute('data-testid') ||
      ''

    const label =
      payload?.element?.getAttribute('data-tracking-label') ||
      payload?.element?.getAttribute('aria-label') ||
      payload?.element?.innerText ||
      ''

    return {
      element: { id, label },
    }
  } else if ('id' in payload.element && 'label' in payload.element) {
    const { id, label } = payload.element
    return {
      element: { id, label },
    }
  }
}

type IRxSelectableConfigValues = keyof Omit<
  ConfiguratorState,
  'frame' | 'prescriptionValues' | 'freeService'
>

const getSelectedRxConfigVariantRefKey = (
  rxConfiguratorState: ConfiguratorState,
): Record<IRxSelectableConfigValues, string> => {
  const result: Record<IRxSelectableConfigValues, string> = {
    lensQuality: '',
    lensType: '',
    tintingType: '',
  }
  Object.keys(result).forEach((keyString) => {
    const key = keyString as IRxSelectableConfigValues
    const value = rxConfiguratorState[key]
    if (value) {
      result[key] = (value as ConfiguratorStateValue).variant.referenceKey || ''
    }
  })
  return result
}

export const getTintingTypeMapped = (
  tintingType: string,
): FimRxConfigurationMapped['configuration']['extras'] | '' => {
  const matched = tintingType.match(/(tinting.*?)(?=-rshc|-shc|$)/g)
  if (!matched) {
    return ''
  }
  return tintingTypeReferenceKeyToTrackingValue[matched[0]] ?? ''
}

export const mapFimRxConfigurationTrackingdata = (
  payload: FimTrackingPayload,
  _options: MapToTrackingPayloadOptions,
): FimRxConfigurationMapped | object => {
  if ('configuration' in payload && payload.configuration) {
    const selectedValues = getSelectedRxConfigVariantRefKey(
      payload.configuration,
    )
    return {
      configuration: {
        prescriptionType: selectedValues.lensType
          ? lensTypeReferenceKeyToTrackingValue[selectedValues.lensType]
          : undefined,
        prescriptionValues:
          isPrescriptionLensType(selectedValues.lensType) &&
          payload.configurator.step > 1
            ? 'Given'
            : 'Not Given',
        glassType: selectedValues.lensQuality || undefined,
        extras: selectedValues.tintingType
          ? getTintingTypeMapped(selectedValues.tintingType)
          : undefined,
      },
    }
  }
  return {}
}

export const mapTrackingDataForFimEvent = (
  event: FimTrackingEvent,
  payload: FimTrackingPayload,
  options: MapToTrackingPayloadOptions,
) => {
  return {
    event,
    ...payload,
    ...mapFimMetaTrackingData(payload, options),
    ...mapFimUserTrackingData(payload, options),
    ...mapFimElementTrackingData(payload, options),
    ...mapFimProductTrackingData(payload, options),
    ...mapFimRxConfigurationTrackingdata(payload, options),
  }
}

export const mapTrackingDataForFimPageViewEvent = (
  event: TrackingEvent,
  payload: TrackingPayload,
  options: MapToTrackingPayloadOptions,
) => {
  return {
    event,
    ...mapFimMetaTrackingData(payload, options),
    ...mapFimUserTrackingData(payload, options),
    ...mapFimErrorTrackingData(payload as PageViewData),
  }
}

export const getEmailHash = async (email: string | undefined) => {
  if (!email) {
    return ''
  }
  if (import.meta.server) {
    const { sha256 } = await import('~/utils/crypto')
    return sha256(email.replace(/ /g, '')?.toLowerCase()).toString()
  }
  const hashBuffer = await crypto.subtle.digest(
    'SHA-256',
    new TextEncoder().encode(email.replace(/ /g, '')?.toLowerCase()),
  )
  return Array.from(new Uint8Array(hashBuffer))
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('')
}

export const addSearchTrackingData = (
  payload: TrackingPayload,
  data: NonNullable<unknown>,
): void => {
  if ('search' in payload && payload.search !== undefined) {
    Object.assign(data, payload.search)
  }
}

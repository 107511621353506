import { defineNuxtPlugin } from '#app'
import type { FitMixInstance, FitMixParameters } from '~/types/fitmix'

declare global {
  interface Window {
    FitMix: {
      WIDGET_URL: string
      createWidget: (
        id: string,
        params: FitMixParameters,
        cb: (ready: boolean) => void,
      ) => FitMixInstance
    }
  }
}

export default defineNuxtPlugin(() => {
  const scriptReady = ref(false)
  const fitmixInstances: Record<string, FitMixInstance> = {}
  const $currentShop = useCurrentShop()

  const vtoApiKey = computed(() => $currentShop.value.vtoApiKey)

  useHead(() => ({
    script: [
      {
        src: 'https://vto-advanced-integration-api.fittingbox.com/index.js',
        key: 'vto-advanced-integration-api',
        async: true,
        defer: true,
        onload: () => {
          scriptReady.value = true
        },
      },
    ],
  }))

  const createFitMixWidget = (
    containerId: string,
    params: FitMixParameters,
    callback: (ready: boolean) => void,
  ) => {
    if (!window.FitMix) {
      console.warn('FitMix not loaded')
      return
    }

    fitmixInstances[containerId] = window.FitMix.createWidget(
      containerId,
      params,
      callback,
    )
    return fitmixInstances[containerId]
  }

  const resetSession = (containerId: string) => {
    fitmixInstances[containerId]?.resetSession()
  }

  const stopVto = (containerId: string) => {
    fitmixInstances[containerId]?.stopVto()
  }

  return {
    provide: {
      fittingBox: {
        scriptReady,
        createFitMixWidget,
        resetSession,
        stopVto,
        vtoApiKey,
      },
    },
  }
})

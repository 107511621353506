import { UpdateBasketItemFailureKind } from '@aboutyou/backbone/helpers/BapiClient.js'
import { ExistingItemHandling } from '@scayle/storefront-api'
import {
  getFirstAttributeValue,
  rpcCall,
  type Awaited,
  type Price as BapiPrice,
  type BasketItem,
  type BasketItemDisplayData,
  type BasketWith,
  type Product,
  type PublicShopConfig,
  type UseBasketParams,
  type Variant,
} from '@scayle/storefront-nuxt'
import type { ProductConfigurationRxItem } from '~/components/ui/productConfiguration/ProductConfigurationItem'
import { useToast } from '~/composables/useToast'
import { BASKET_WITH } from '~/constants/withParams'
import { Action } from '~/plugins/toast'
import type { IAddOrUpdateItems } from '~/rpcMethods/basket'
import type { DdoaProduct } from '~/types/ddoa'

export interface ItemGroup {
  id: string
  isMainItem: boolean
  isRequired: boolean
}
export interface ItemGroupMainItem {
  id: string
  isMainItem: true
  isRequired: true
}

export interface FimBasketItemCustomData {
  itemGroup?: ItemGroup
  prescriptionValues?: RxPrescriptionValues
  shopId?: number
  configurationItems?: ProductConfigurationRxItem[]
  rxSubItemInfo?: RxSubItemInfo[]
  subscriptionDefinition?: SubscriptionDefinition
  weeeRegistrationNumber?: string
  designerOrder?: boolean
  branchId?: string
  clientId?: string
  sessionId?: string
  isContactLens?: boolean
  quantity?: {
    left?: number
    right?: number
  }
}
export interface FimBasketItemCustomDataMain extends FimBasketItemCustomData {
  itemGroup: ItemGroupMainItem
  shopId: number
}

export interface FimBasketItem extends BasketItem {
  itemGroup?: ItemGroup
  customData: FimBasketItemCustomData
  price: {
    total: BapiPrice
    unit: BapiPrice
  }
}

/**
 * Get base custom data
 * @param item basket item
 * @param currentShop currentShop
 */
const getBaseCustomData = async (
  currentShop: MaybeRefOrGetter<PublicShopConfig | undefined>,
  customData?: MaybeRefOrGetter<FimBasketItemCustomData | undefined>,
): Promise<FimBasketItemCustomData> => {
  const branchId = getBranchIdFromSession()
  try {
    const sessionId = await getGoogleTagProperty('session_id', currentShop)
    const clientId = await getGoogleTagProperty('client_id', currentShop)

    return {
      ...toValue(customData),
      ...(sessionId && { sessionId }),
      ...(clientId && { clientId }),
      ...(branchId && { branchId }),
    }
  } catch (error) {
    console.warn(error instanceof Error ? error.message : error)
  }

  return {
    ...toValue(customData),
    ...(branchId && { branchId }),
  }
}

export const useFimBasket = (params?: UseBasketParams) => {
  const nuxtApp = useNuxtApp()
  const { logger } = useLogging()
  const { trackAddToBasket } = useTrackingEvents()
  const { $i18n, $currentShop } = nuxtApp
  const refetching = ref(false)
  const { alert } = useToast()

  const basket = useBasket({
    key: 'useBasket',
    with: BASKET_WITH,
    ...(params || {}),
  })

  const { data: shopCustomData } = useCustomData()

  const fimBasketItems = computed(() =>
    basket?.items?.value
      ? basket.items?.value.map((item) => {
          return item as FimBasketItem
        })
      : [],
  )

  const rxItemsAndOptions = computed((): FimBasketItem[] =>
    fimBasketItems.value.filter((item) => hasItemGroup(item)),
  )

  const rxItems = computed(() => {
    return fimBasketItems.value
      .filter((item) => isRxMainItem(item))
      .map((item) => {
        const options = (
          getItemGroupAddonItems(
            fimBasketItems.value,
            getItemGroupId(item),
          ) as FimBasketItem[]
        ).map((option) =>
          getProductConfigRxItem(
            option.product,
            option.variant,
            option.price.total,
          ),
        )
        if (hasItemGroup(item)) {
          item.customData.configurationItems = options
        }
        return item
      })
  })

  const basketItems = computed(() => {
    if (!basket?.items?.value) {
      return []
    }

    const regularItems = fimBasketItems.value.filter(
      (item) => !hasItemGroup(item),
    )

    const itemsOrder = basket.items.value.map((item) => item.product.id)

    return [...regularItems, ...rxItems.value].sort(
      (a, b) =>
        itemsOrder.indexOf(a.product.id) - itemsOrder.indexOf(b.product.id),
    )
  })

  const unavailableRxItems = computed(() => {
    // check available quantity
    const basketQuantities = rxItems.value.reduce(
      (res, item) => {
        const quantity = res?.[item.variant.id] ?? 0
        return { ...res, [item.variant.id]: quantity + 1 }
      },
      {} as Record<FimBasketItem['variant']['id'], number>,
    )

    return rxItems.value.filter((item) => {
      const isMainItemUnavailable =
        !isProductAvailable(item.product) ||
        !isVariantAvailable(item.product, item.variant)
      const isAnySubItemUnavailable = item.customData?.configurationItems?.some(
        (subItem) =>
          !isProductAvailable(subItem.product) ||
          !isVariantAvailable(subItem.product, subItem.variant),
      )

      // check availableQuantity
      const isAvailableQuantityExceeded =
        item.availableQuantity !== null &&
        item.availableQuantity !== undefined &&
        item.availableQuantity < basketQuantities[item.variant.id]

      // reduce computed basketQuantity by one because the item will either be removed or it doesn't matter anyway
      basketQuantities[item.variant.id] = basketQuantities[item.variant.id] - 1

      return (
        isMainItemUnavailable ||
        isAnySubItemUnavailable ||
        isAvailableQuantityExceeded
      )
    })
  })

  const basketCount = computed(() =>
    basketItems.value.reduce((prev, current) => prev + current.quantity, 0),
  )

  const basketCountWithoutSoldOutItems = computed(() =>
    basketItems.value.reduce(
      (prev, current) =>
        prev + (current.product.isSoldOut ? 0 : current.quantity),
      0,
    ),
  )

  const handleAddOrUpdateError = (
    response: Awaited<ReturnType<typeof addOrUpdateItemsRpc>>,
  ) => {
    if (response && response.type === 'failure') {
      const ids = response.errors.map((err: any) => err.variantId).join(',')
      const message = `[addOrUpdateItems] failed to add or update items (${ids}) for basket ${basket.key}`
      logger.error(message, {
        why: 'addOrUpdateItems',
        where: 'useFimBasket.ts',
      })
    }
  }

  const addOrUpdateItemsRpc = rpcCall(nuxtApp, 'addOrUpdateItems', $currentShop)

  const addOrUpdateBasketItems = async (
    itemsSerialized: IAddOrUpdateItems['items'],
    options?: IAddOrUpdateItems['options'],
    promotionId?: string,
  ) => {
    basket.fetching.value = true
    const baseCustomData = await getBaseCustomData($currentShop)
    const items: IAddOrUpdateItems['items'] = itemsSerialized.map((item) => {
      const { variantId, quantity, params } = item
      const customData = { ...baseCustomData, ...params?.customData }
      return {
        variantId,
        quantity,
        params: {
          ...params,
          customData,
          with: BASKET_WITH,
          promotionId,
        },
      }
    })
    const basketParams: IAddOrUpdateItems['basketParams'] = {
      with: BASKET_WITH,
    }

    const isRx = itemsSerialized.some((item) => hasItemGroup(item as RXItem))

    try {
      const response = await addOrUpdateItemsRpc({
        items,
        options,
        basketParams,
      })

      if (response) {
        basket.data.value = response.basket

        if (isRx) {
          // API returns a false success in some cases so we cannot rely on it
          // for error handling, need to check added RX items are valid ourselves
          const removed = await removeInvalidRxItems()
          // Some items were removed, show error to user
          if (removed) {
            throw new Error(UpdateBasketItemFailureKind.ItemUnvailable)
          }
          // Some other kind of error happened
          handleAddOrUpdateError(response)
        } else {
          // Some other kind of error happened
          handleAddOrUpdateError(response)
        }
      }
    } finally {
      basket.fetching.value = false
    }
  }

  const removeBasketItem = async (
    item: BasketItem<Product, Variant>,
    basketWith?: BasketWith,
  ) => {
    try {
      basket.data.value = await rpcCall(
        nuxtApp,
        'removeItemFromBasket',
        $currentShop,
      )({
        itemKey: item.key,
        with: basketWith || BASKET_WITH,
      })
    } catch (error) {
      const message = `[removeBasketItems]: Could not delete item "${item.key}" from basket "${basket.key}"`

      logger.error(error, {
        why: 'removeItemFromBasket',
        where: 'useFimBasket.ts',
        message,
      })
    }
  }

  const removeBasketItems = async (
    items: BasketItem<Product, Variant>[],
    basketWith?: BasketWith,
  ) => {
    const getBasketItemKeys = () =>
      basket.data.value?.items.map((item) => item.key) ?? []

    for (const item of items) {
      const keysAvailable = getBasketItemKeys() ?? []
      if (keysAvailable.includes(item.key)) {
        await removeBasketItem(item, basketWith)
      }
    }
  }

  const removeUnavailableRxItems = async () => {
    if (unavailableRxItems.value.length) {
      await Promise.all(
        unavailableRxItems.value.map((unavailableItem) => {
          const deleteItems = rxItemsAndOptions.value.filter(
            (item) => getItemGroupId(item) === unavailableItem.itemGroup?.id,
          )
          const notificationMessage = $i18n.t('basket.item_group_removed', {
            productName: getFirstAttributeValue(
              unavailableItem.product.attributes,
              'name',
            )?.label,
            quantity: unavailableItem.availableQuantity,
          })
          alert(notificationMessage, Action.error)
          return removeBasketItems(deleteItems)
        }),
      )
    }
  }

  const removeInvalidRxItems = async (): Promise<boolean> => {
    const rxItems = fimBasketItems.value.filter(
      (item) => isRxMainItem(item as RXItem) || isRxAddonItem(item as RXItem),
    )
    const rxItemGroupIds = new Set(
      rxItems.map((item) => getItemGroupId(item as RXItem)),
    )
    // Validate every group
    const invalidItems: FimBasketItem[] = []

    for (const itemGroupId of rxItemGroupIds) {
      if (!validateRxItem(itemGroupId, fimBasketItems.value)) {
        const itemsInGroup = rxItems.filter(
          (item) => itemGroupId === getItemGroupId(item as RXItem),
        )
        // Not valid, need to remove all items from this group
        invalidItems.push(...itemsInGroup)
      }
    }

    if (invalidItems.length) {
      try {
        await removeBasketItems(invalidItems)
      } catch (error: unknown) {
        logger.error(error, {
          why: 'removeBasketItems',
          where: 'useFimBasket.ts',
        })
      }
      return true
    }
    return false
  }

  const mergeBasketsRpc = rpcCall(nuxtApp, 'mergeBaskets', $currentShop)
  type IMergeBaskets = Parameters<typeof mergeBasketsRpc>[0]

  const fimMergeBaskets = async (
    args: Pick<IMergeBaskets, 'fromBasketKey' | 'toBasketKey'>,
  ) => {
    const result = await rpcCall(
      nuxtApp,
      'mergeBaskets',
      $currentShop,
    )({
      ...args,
      with: BASKET_WITH,
      options: {
        considerItemGroupForUniqueness: true,
        existingItemHandling: ExistingItemHandling.AddQuantityToExisting,
      },
    })

    await removeUnavailableRxItems()

    return result
  }

  const handleInvalidBasketItems = async () => {
    refetching.value = true
    try {
      await basket.fetch()
      await removeUnavailableRxItems()
      await removeInvalidRxItems()
    } finally {
      refetching.value = false
    }
  }

  const basketCost = computed(() => {
    return basket?.data?.value?.cost
  })

  const addDdoaProductToBasket = async (item: DdoaProduct) => {
    const displayData: BasketItemDisplayData = {}
    const baseCustomData = await getBaseCustomData($currentShop)

    let customData = item.customData
    if (item.prescriptionValues && item.quantity && item.product) {
      const eye = getEyeFromSightSpecification({
        prescriptionValues: item.prescriptionValues,
      })
      customData = getCustomDataForBasket(
        item.prescriptionValues,
        eye,
        $currentShop,
        {
          left: Math.ceil(item.quantity / 2),
          right: Math.floor(item.quantity / 2),
        },
        getProductCategory(item.product),
      ) as FimBasketItemCustomData
    }

    if (item.selectedVariant && item.product) {
      try {
        await basket.addItem({
          variantId: item.selectedVariant.id,
          quantity: item.quantity || 1,
          displayData,
          customData: { ...baseCustomData, ...customData },
        })

        trackAddToBasket(
          item.product,
          item.quantity || 1,
          item.selectedVariant,
          {
            customData: { ...baseCustomData, ...customData },
          },
        )
      } catch (error) {
        logger.error(error, {
          why: 'addOrderItemToBasket',
          where: 'useFimBasket.ts',
        })
        throw error
      }
    } else {
      const error = new Error('Missing variant and/or quantity from ddoa item')
      logger.error(error, {
        why: 'addOrderItemToBasket',
        where: 'useFimBasket.ts',
        item,
      })
      throw error
    }
  }

  const addOrderItemToBasket = async (
    orderItem: FimOrderItem,
    orderItems: FimOrderItem[],
  ) => {
    let displayData: BasketItemDisplayData = {}
    if (
      getProductCategory(orderItem.product) === ProductCategories.contactLenses
    ) {
      displayData = getDisplayDataForOrder(
        orderItem,
        orderItems,
        $i18n,
        $currentShop,
        shopCustomData.value,
      )
    }
    const baseCustomData = await getBaseCustomData($currentShop)
    let customData = orderItem.customData
    const sightSpec = getSightSpecificationFromCustomData(orderItem.customData)
    if (sightSpec) {
      const eye = getEyeFromSightSpecification(sightSpec)
      const customDataQuantity = getQuantity(eye, orderItem.quantity)
      customData = getCustomDataForBasket(
        sightSpec.prescriptionValues,
        eye,
        $currentShop,
        customDataQuantity,
        getProductCategory(orderItem.product),
      )
    }

    try {
      await basket.addItem({
        variantId: orderItem.variant.id,
        quantity: orderItem.quantity,
        displayData,
        customData: { ...baseCustomData, ...customData },
      })
    } catch (error) {
      logger.error(error, {
        why: 'addOrderItemToBasket',
        where: 'useFimBasket.ts',
      })
      throw error
    }
  }

  // Adds base customData to items
  const wrappedAddItem: typeof basket.addItem = async (item) => {
    const baseCustomData = await getBaseCustomData($currentShop)
    return basket.addItem({
      ...item,
      customData: { ...baseCustomData, ...item.customData },
    })
  }

  // Adds base customData to items
  const wrappedAddItems: typeof basket.addItems = async (
    items,
    existingItemHandling,
  ) => {
    const baseCustomData = await getBaseCustomData($currentShop)
    return basket.addItems(
      items.map((item) => ({
        ...item,
        customData: { ...baseCustomData, ...item.customData },
      })),
      existingItemHandling,
    )
  }

  return extendPromise(
    basket.then(() => ({})),
    {
      data: basket.data,
      key: basket.key,
      packages: basket.packages,
      shippingDates: basket.shippingDates,
      isEmpty: basket.isEmpty,
      fetching: basket.fetching,
      fetch: basket.fetch,
      addItem: wrappedAddItem,
      addItems: wrappedAddItems,
      removeItem: basket.removeItem,
      clear: basket.clear,
      contains: basket.contains,
      products: basket.products,
      findItem: basket.findItem,
      cost: basketCost,
      count: basketCount,
      countWithoutSoldOutItems: basketCountWithoutSoldOutItems,
      items: basketItems,
      mergeBaskets: fimMergeBaskets,
      rxItemsAndOptions,
      addOrUpdateItems: addOrUpdateBasketItems,
      removeBasketItems,
      removeBasketItem,
      unavailableRxItems,
      handleInvalidBasketItems,
      refetching,
      addOrderItemToBasket,
      addDdoaProductToBasket,
    },
  )
}

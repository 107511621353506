import type {
  BasketItemDisplayData,
  BasketPackageInformation,
  VariantDetail,
} from '@scayle/storefront-api'
import {
  getFirstAttributeValue,
  type ListOfPackages,
  type Order,
  type Product,
  type PromiseReturnType,
  type RpcMethodReturnType,
  type Variant,
} from '@scayle/storefront-nuxt'
import type { NuxtApp } from 'nuxt/app'
import {
  type DeliveryStatus,
  DELIVERY_STATUS_CANCELLATION_COMPLETED,
  DELIVERY_STATUS_CANCELLED,
  DELIVERY_STATUS_DELEGATION_PENDING,
  DELIVERY_STATUS_OPEN,
  DELIVERY_STATUS_PARTIALLY_CANCELLED,
  DELIVERY_STATUS_PARTIALLY_RETURNED,
  DELIVERY_STATUS_RETURNED,
  DELIVERY_STATUS_SHIPMEMT_COMPLETED,
  DELIVERY_STATUS_SHIPPMENT_PENDING,
  type DeliveryCarrier,
  deliveryCarriers,
} from '../constants/order'
import type { RxConfMasterKeys } from '../rpcMethods/rxTypes'
import type { ObjectWith } from './types'
import { getContactLensDisplayData } from './contactLenses'
import {
  getEyeFromSightSpecification,
  getSightSpecificationFromCustomData,
} from './customData'
import { getNormalizedLocale } from './locale'
import { isDummyProduct } from './product'
import { getItemGroup, hasItemGroup, isRxMainItem } from './rx'
import type { FimBasketItem } from '~/composables/useFimBasket'
import type { DeliveryForecastAttribute } from '~/composables/pdp/useLenses'
import type { OrderStatusResponse } from '~/rpcMethods/orders'
import type { CustomStoreConfig } from '~/config/stores/types'

export type DummyMasterKey = 'DUMMY'

export type FimOrderItem = Omit<OrderItem, 'product' | 'variant'> & {
  product: Product & {
    masterKey?: RxConfMasterKeys | DummyMasterKey
    name?: string
  }
  variant: Variant
  klappVariantId?: string
  quantity: number
  displayData?: BasketItemDisplayData
}

export type RxMainOrderItem = Omit<FimOrderItem, 'customData'> & {
  customData: { items: FimOrderItem[]; [t: string]: unknown }
}

export type OrderPackageInformation = ListOfPackages[number]
type DeliveryPackage = OrderPackageInformation | BasketPackageInformation
type OrderItems<T> = T extends OrderPackageInformation
  ? FimOrderItem
  : FimBasketItem
export type OrderDeliveryPackage = OrderPackageInformation & {
  items: FimOrderItem[]
  tracking?: { url: string; id: string }
}
export type BasketDeliveryPackage = BasketPackageInformation & {
  items: FimBasketItem[]
}

const getOriginalDeliveryStatus = (order: Order | null) => {
  return order?.packages?.[0]?.deliveryStatus ?? ''
}

const hasDeliveryStatus = (order: Order, status: string) =>
  order.packages?.some((orderPackage) => orderPackage.deliveryStatus === status)

export const getDeliveryStatus = (order: Order | null): DeliveryStatus => {
  if (
    order?.items?.length &&
    order?.items?.every(
      (item) =>
        item.status === 'returned' &&
        hasDeliveryStatus(order, 'shipment_completed'),
    )
  ) {
    return DELIVERY_STATUS_RETURNED
  } else if (
    order?.items?.some(
      (item) =>
        item.status === 'returned' &&
        hasDeliveryStatus(order, 'shipment_completed'),
    )
  ) {
    return DELIVERY_STATUS_PARTIALLY_RETURNED
  } else if (
    order?.items?.length &&
    order?.items?.every((item) =>
      ['cancelled', 'unavailable'].includes(item.status),
    )
  ) {
    return DELIVERY_STATUS_CANCELLED
  } else if (
    order?.items?.some((item) =>
      ['cancelled', 'unavailable'].includes(item.status),
    )
  ) {
    return DELIVERY_STATUS_PARTIALLY_CANCELLED
  } else {
    return getOriginalDeliveryStatus(order)
  }
}

export const getProgressLevel = (
  deliveryStatus: DeliveryStatus | undefined | '',
) => {
  switch (deliveryStatus) {
    case DELIVERY_STATUS_OPEN:
    case DELIVERY_STATUS_CANCELLED:
    case DELIVERY_STATUS_PARTIALLY_CANCELLED:
    case DELIVERY_STATUS_CANCELLATION_COMPLETED:
      return 1
    case DELIVERY_STATUS_DELEGATION_PENDING:
      return 2
    case DELIVERY_STATUS_SHIPPMENT_PENDING:
      return 3
    case DELIVERY_STATUS_SHIPMEMT_COMPLETED:
    case DELIVERY_STATUS_RETURNED:
    case DELIVERY_STATUS_PARTIALLY_RETURNED:
      return 4
    default:
      return 1
  }
}

export const getCancellationStatus = (deliveryStatus: DeliveryStatus) => {
  return [
    DELIVERY_STATUS_CANCELLED,
    DELIVERY_STATUS_PARTIALLY_CANCELLED,
    DELIVERY_STATUS_CANCELLATION_COMPLETED,
  ].includes(deliveryStatus)
}

const isEqualOrderItem = (
  a: OrderItem | FimOrderItem,
  b: OrderItem | FimOrderItem,
) => {
  if (isDummyProduct(a.product) && isDummyProduct(b.product)) {
    return a.variant.referenceKey === b.variant.referenceKey
  }
  return (
    a.variant.id === b.variant.id ||
    ('klappVariantId' in a && +(a.klappVariantId ?? '') === b.variant.id) ||
    ('klappVariantId' in b && +(b.klappVariantId ?? '') === a.variant.id)
  )
}

export const makeOrderItemsUnique = <T extends DeliveryPackage>(
  orderItems: Order['items'] | OrderItems<T>[],
) => {
  const fimOrderItems = (orderItems ?? []) as unknown as FimOrderItem[]
  const uniqueItems = fimOrderItems.filter((item, index, array) => {
    if (hasItemGroup(item)) {
      return true
    }

    return (
      array.findIndex((i) =>
        isEqualOrderItem(
          item as unknown as FimOrderItem,
          i as unknown as FimOrderItem,
        ),
      ) === index
    )
  })

  return uniqueItems.map((item) => ({
    ...item,
    quantity: getOrderItemQuantity(fimOrderItems, item),
  }))
}

export const getUniqueItems = (order: Order) =>
  makeOrderItemsUnique(order?.items)

// filter to keep only main items and items without item Group then we add the subitems to customData
export const groupItems = <T extends DeliveryPackage>(
  orderItems: Order['items'] | FimOrderItem[] | OrderItems<T>[],
): FimOrderItem[] => {
  const fimOrderItems = (orderItems ?? []) as unknown as FimOrderItem[]
  return fimOrderItems
    .filter((orderItem) => isRxMainItem(orderItem) || !hasItemGroup(orderItem))
    .map((orderItem) => {
      if (
        isRxMainItem(orderItem) &&
        !((orderItem?.customData?.items || []) as FimOrderItem[])?.length
      ) {
        const addOnItems = fimOrderItems.filter(
          (item) =>
            !isRxMainItem(item) &&
            getItemGroup(item)?.id === getItemGroup(orderItem)?.id,
        )

        return {
          ...orderItem,
          ...{
            customData: {
              ...(orderItem?.customData ?? {}),
              items: addOnItems,
            },
          },
        }
      } else {
        return orderItem
      }
    })
}

export function groupItemsByPackages<T extends DeliveryPackage>(
  orderPackages: T[] | undefined,
  items: OrderItems<T>[] | FimOrderItem[] | Order['items'],
) {
  return (orderPackages ?? [])
    .map((orderPackage) => {
      const fimOrderItems = (items ?? []) as unknown as FimOrderItem[]
      const packageItems = groupItems<T>(
        fimOrderItems.filter((item) => item.packageId === orderPackage.id) ??
          [],
      )

      const uniquePackageItems = makeOrderItemsUnique(packageItems)

      return {
        ...orderPackage,
        deliveryDate: {
          min:
            'min' in orderPackage.deliveryDate
              ? orderPackage.deliveryDate.min
              : orderPackage.deliveryDate.minimum,
          max:
            'max' in orderPackage.deliveryDate
              ? orderPackage.deliveryDate.max
              : orderPackage.deliveryDate.maximum,
        },
        items: uniquePackageItems,
      } as unknown as Omit<T, 'deliveryDate'> & {
        deliveryDate: BasketPackageInformation['deliveryDate']
        items: OrderItems<T>[]
      }
    })
    .filter((orderPackage) => orderPackage.items.length > 0)
    .sort(
      (a, b) =>
        new Date(a.deliveryDate.min).getTime() -
        new Date(b.deliveryDate.min).getTime(),
    )
}

export const formatDeliveryDate = (
  date: string,
  $currentShop: NuxtApp['$currentShop'],
) =>
  new Intl.DateTimeFormat(getNormalizedLocale($currentShop.locale), {
    month: 'numeric',
    day: 'numeric',
  }).format(new Date(date))

export const getOrderItemQuantity = (
  orderItems: FimOrderItem[] | Order['items'] | null,
  orderItem: FimOrderItem,
) => {
  if (hasItemGroup(orderItem) || !Array.isArray(orderItems)) {
    return 1
  }

  const initialValue = orderItem.quantity || 1
  const additional =
    (orderItems as FimOrderItem[]).filter((value) =>
      isEqualOrderItem(value, orderItem),
    ).length - 1

  return initialValue + additional
}

export const getDisplayDataForOrder = (
  item: FimOrderItem,
  orderItems: FimOrderItem[] | undefined,
  i18n: NuxtApp['$i18n'],
  $currentShop: NuxtApp['$currentShop'],
  shopCustomData?: CustomData,
) => {
  const numberOfLenses =
    getOrderItemQuantity(orderItems, item)?.toString() || ''

  const sightSpec = getSightSpecificationFromCustomData(item.customData)
  const eye = sightSpec && getEyeFromSightSpecification(sightSpec)

  return getContactLensDisplayData(
    i18n,
    $currentShop,
    sightSpec?.prescriptionValues,
    eye,
    item.variant.stock,
    shopCustomData,
    numberOfLenses,
  )
}

export const getOrderId = (
  orderId: number | string | undefined | null,
  shopId: number | string | undefined | null,
  availableShops: NuxtApp['$currentShop'][],
) => {
  if (shopId) {
    const orderShop = availableShops.find(
      (shop) => Number(shop.shopId) === Number(shopId),
    )
    return orderShop ? `${orderShop.name}-${shopId}-${orderId}` : `${orderId}`
  }
  return `${orderId}`
}
export const formatOrderId = (
  inputId: string,
  response: OrderStatusResponse,
) => {
  const branch = response?.NiederlassungNummer ?? ''
  const job = response?.AuftragNummer ?? ''
  const checkDigit = (inputId ?? '').slice(-1)
  return branch && job ? [branch, job, checkDigit].join(' ') : inputId
}

export type OrderDetails = Exclude<
  Awaited<RpcMethodReturnType<'getOrderById'>>,
  Response
>

export const isKlappOrder = (orderDetails: MaybeRefOrGetter<OrderDetails>) =>
  Boolean(toValue(orderDetails)?.referenceKey?.startsWith('fielmann_klapp_'))

export const isImportedOrder = (orderDetails: MaybeRefOrGetter<OrderDetails>) =>
  toValue(orderDetails)?.customData?.origin === 'data-importer'

type OrderReturnUrlGetter = (
  orderReturnPortalDomain: string,
  orderId: string,
  customerEmail: string,
) => string

// https://aboutyou.atlassian.net/jira/software/c/projects/SCFIM/boards/2870?modal=detail&selectedIssue=SCFIM-1800
const getSevenSendersReturnUrl: OrderReturnUrlGetter = (
  orderReturnPortalDomain,
  orderId,
  customerEmail,
) => {
  const sevenSendersReturnUrl = new URL(orderReturnPortalDomain)
  const orderDetailsId = orderId.split('-').pop()
  if (!orderDetailsId) {
    return ''
  }
  sevenSendersReturnUrl.searchParams.append('order', orderDetailsId)
  sevenSendersReturnUrl.searchParams.append('email', customerEmail)
  return sevenSendersReturnUrl.toString()
}

const getDefaultReturnUrl: OrderReturnUrlGetter = (
  orderReturnPortalDomain,
  orderId,
  customerEmail,
) => {
  const defaultReturnUrl = new URL('/orders', orderReturnPortalDomain)
  defaultReturnUrl.searchParams.append('filter[invoice_number]', orderId)
  defaultReturnUrl.searchParams.append('filter[customer][email]', customerEmail)
  return defaultReturnUrl.toString()
}

export const getOrderReturnUrl = (
  orderPackages: ObjectWith<{
    deliveryStatus: OrderPackageInformation['deliveryStatus']
  }>[],
  $currentShop: ObjectWith<Pick<CustomStoreConfig, 'orderReturnPortal'>>,
  orderId?: string,
  customerEmail?: string,
) => {
  const allShipmentsCompleted = orderPackages.every(
    (orderPackage) => orderPackage.deliveryStatus === 'shipment_completed',
  )
  if (
    orderId &&
    customerEmail &&
    $currentShop.orderReturnPortal.domain &&
    allShipmentsCompleted
  ) {
    const PORTAL_URL_GETTERS: Record<
      typeof $currentShop.orderReturnPortal.portal,
      OrderReturnUrlGetter
    > = {
      DEFAULT: getDefaultReturnUrl,
      SEVEN_SENDERS: getSevenSendersReturnUrl,
    }
    return PORTAL_URL_GETTERS[$currentShop.orderReturnPortal.portal](
      $currentShop.orderReturnPortal.domain,
      orderId,
      customerEmail,
    )
  }
  return ''
}

export const getTrackingUrl = (
  orderOrPackage: ObjectWith<{
    tracking?: {
      id?: string
      url?: string
    }
  }>,
) => {
  const tracking = orderOrPackage.tracking
  if (tracking?.id && tracking?.url) {
    // sometimes we get urls that already contain the id as well as some
    // replacement pattern like {id} or {trackingId}
    // In that case we remove the id from the url
    const trackingUrl = /\{[^}]*id[^}]*/i.test(tracking.url)
      ? tracking.url.replace(tracking.id, '')
      : tracking.url

    // and replace the pattern with the id
    return trackingUrl.replace(/\{([^}]+)\}/, (_, p1) => {
      if (p1.toLowerCase().includes('id')) {
        return tracking.id ?? ''
      }
      return ''
    })
  } else if (tracking?.url) {
    // if there's no id but a pattern we simply remove the pattern
    return tracking.url?.replaceAll(/\{([^}]+)\}/g, '') ?? ''
  }
  return ''
}

export const isDeliveryCarrier = (
  carrier: unknown,
): carrier is DeliveryCarrier =>
  deliveryCarriers.includes(carrier as DeliveryCarrier)

export const isOrderItemVariantAvailable = (
  variants: Variant[] | VariantDetail[] | null,
  variantId: number,
) => {
  return variants?.some(
    (item) =>
      item.id === variantId &&
      ((item?.stock?.quantity ?? 0) > 0 || item?.stock?.isSellableWithoutStock),
  )
}

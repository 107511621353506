/* eslint sonarjs/cognitive-complexity: 1 */

import type { Category, Product, Value } from '@scayle/storefront-nuxt'
import type { NuxtApp } from 'nuxt/app'
import { PRIVACY_PAGE_SLUGS } from '../constants/pageType'
import type { RouteLocation } from '#vue-router'
import { PDPComponentType } from '../types/pdp'
import { getPDPComponentType } from '../utils/pdp'
import { getFirstAttributeValue } from '../utils/attribute'
import baseSlugify from 'slugify'
import type { SubscriptionProduct } from '../types/subscription'
export { slugify } from '@scayle/storefront-nuxt'

const slugifyOptions = { lower: true, remove: /[/*+~.()'"!:@]/g }

const getCategoryPath = (category: Category) => {
  if (!category) {
    return
  }
  return `${category.path}`
}

export const normalizePath = (path: string) => {
  return `/${path.split('/').filter(Boolean).join('/')}/`.replace(/\/+/, '/')
}

type Link =
  | 'search'
  | 'wishlist'
  | 'basket'
  | 'product'
  | 'store'
  | 'stores'
  | 'citypage'
  | 'checkout'
  | 'signin'
  | 'signup'
  | 'signout'
  | 'account'
  | 'order'
  | 'branchOrder'
  | 'branchOrderDetail'
  | 'orderDetail'
  | 'orderStatus'
  | 'user'
  | 'home'
  | 'ropoBasket'
  | 'ropoPlp'
  | 'appointments'
  | 'success'
  | 'subscriptions'
  | 'subscriptionsDetail'

export type LinkList = Record<
  Link,
  {
    name: string
    path: string
    isProtected?: boolean
    parameter?: string
    query?: { [key: string]: string }
  }
>

export const routeList: LinkList = {
  home: { name: 'index', path: '/' },
  search: { name: 'search', path: '/search/' },
  wishlist: { name: 'wishlist', path: '/wishlist/' },
  basket: { name: 'basket', path: '/basket/' },
  product: { name: 'p-slug', path: '/p/' },
  stores: { name: 'niederlassungen', path: '/niederlassungen/' },
  store: {
    name: 'niederlassungen-city-id',
    path: '/niederlassungen/:city/:id/',
  },
  citypage: {
    name: 'niederlassungen-stadt-county-city',
    path: '/niederlassungen/:county/:city/',
  },
  signin: { name: 'signin', path: '/signin/' },
  signup: { name: 'signin', path: '/signin', query: { register: 'true' } },
  signout: { name: 'signout', path: '/signout/' },
  account: { name: 'account', path: '/account/', isProtected: true },
  checkout: { name: 'checkout', path: '/checkout/', isProtected: true },
  order: {
    name: 'account-orders',
    path: '/account/orders/',
    isProtected: true,
  },
  branchOrder: {
    name: 'account-branch-order',
    path: '/account/branch-order/',
    isProtected: true,
  },
  branchOrderDetail: {
    name: 'account-branch-order-id',
    path: '/account/branch-order',
    parameter: 'id',
    isProtected: true,
  },
  user: { name: 'account-user', path: '/account/user/', isProtected: true },
  orderStatus: {
    name: 'service-auftragsstatus',
    path: '/service/auftragsstatus/',
  },
  orderDetail: {
    name: 'account-orders-id',
    path: '/account/orders/:id',
    parameter: 'id',
    isProtected: true,
  },
  ropoBasket: {
    name: 'ropo-basket',
    path: '/anprobeliste/',
  },
  ropoPlp: {
    name: 'ropo-plp',
    path: '/anprobevorort/',
  },
  appointments: {
    name: 'account-appointments',
    path: '/account/appointments',
    isProtected: true,
  },
  success: {
    name: 'success',
    path: '/success',
    parameter: 'cbd',
  },
  subscriptions: {
    name: 'account-subscriptions',
    path: '/account/subscriptions/',
    isProtected: true,
  },
  subscriptionsDetail: {
    name: 'account-subscriptions-id',
    path: '/account/subscriptions/:id/',
    isProtected: true,
  },
} as const

export const getStoreDetailRoute = (cityName: string) => {
  return {
    name: routeList.stores.name,
    params: {
      slug: cityName,
    },
  }
}

export const getSearchRoute = (term: string) => {
  return {
    name: 'search',
    query: { term },
  }
}

export const getSubscriptionDetailRoute = (id: number) => {
  return {
    name: routeList.subscriptionsDetail.name,
    path: routeList.subscriptionsDetail.path,
    params: {
      id,
    },
  }
}
export const getOrderDetailRoute = (id: string) => {
  return {
    name: routeList.orderDetail.name,
    path: routeList.orderDetail.path,
    params: {
      id,
    },
  }
}

export const getProtectedRouteList = (exclude?: string): string[] => {
  return Object.entries(routeList)
    .filter(([key, value]) => value.isProtected && exclude !== key)
    .map(([, value]) => value.path)
}

/* @legacy? */
export const getBaseUrl = (
  $currentShop: NuxtApp['$currentShop'],
  $config: NuxtApp['$config'],
) => {
  const baseUrl = new URL($config.public.baseUrl) // always returns AT domain
  const domain = $currentShop.domain

  const url = new URL(
    domain.includes('://') ? domain : `${baseUrl.protocol}//${domain}`,
  )

  url.port = baseUrl.port

  return url.toString()
}

/**
 * Normalizes and combines URL paths, ensuring proper formatting and protocol handling.
 * If `base` is provided, it will be used as the base URL + base path for the purpose of resolving non-absolute `input` URLs.
 *
 * @param input The absolute or relative input URL to parse.
 *              If `input` is relative, then `base` is required.
 *              If `input` is absolute, the `base` is ignored.
 * @param base The base URL to resolve against if the `input` is not absolute.
 */
export const normalizeUrl = (
  input: string | undefined | Array<string | undefined>,
  base?: string | URL,
) => {
  const inputs = Array.isArray(input) ? input : [input]

  // Concatenate paths, trimming whitespace and filtering out falsy values
  input = inputs
    .map((p) => p?.trim())
    .filter(Boolean)
    .map((p) => {
      if (p.startsWith('/')) {
        return p
      }

      const [start] = p?.split('/') ?? []
      return start?.includes('.') || start?.includes(':') ? p : `/${p}`
    })
    .join('/')

  // Determine if input is just a path or a full url which might be missing the protocol
  const hasRelativeProtocol = input.startsWith('//') // e.g. "//example.com"
  const isRelativeUrl = !hasRelativeProtocol && /^\.*\//.test(input) // e.g. "./path" or "/path"
  if (input && !isRelativeUrl) {
    // Add https:// if no protocol is present
    input = input.replace(/^(?!(?:\w+:)?\/\/)|^\/\//, 'https://')
  }

  // Get base path from base url if input is relative and base URL is provided
  const basePath = !input?.includes('://') && base ? new URL(base).pathname : ''

  // Combine base path and input, removing duplicate slashes (except in protocol)
  input = [basePath, input]
    .filter(Boolean)
    .join('/')
    .replaceAll(/(?<!:)\/+/gm, '/')

  // Return normalized URL object
  return new URL(input, base)
}

export const trailingSlash = (path: string) => {
  if (path.includes('://')) {
    return path
  }

  const url = new URL(path, 'https://example.com')

  if (!url.pathname.endsWith('/')) {
    url.pathname += '/'
  }

  return url.toString().replace('https://example.com', '')
}

export const getCanonicalRoute = (baseUrl: string, path: string): string => {
  try {
    const url = new URL(
      path,
      /:\/\//.test(baseUrl) ? baseUrl : `https://${baseUrl}`,
    )
    url.pathname = (url.pathname + '/').replace(/\/+/gm, '/')

    const validKeys = ['page']

    if (url.searchParams.get('page') === '1') {
      url.searchParams.delete('page')
    }

    for (const key of url.searchParams.keys()) {
      if (!validKeys.includes(key)) {
        url.searchParams.delete(key)
      }
    }

    return url.toString().replace(/[?&]$/, '')
  } catch (error) {
    return ''
  }
}

export const isNumericIdRoute = (routePath: string) => {
  const pathWithoutSlashes = routePath.replaceAll('/', '')
  return /^\d+$/.test(pathWithoutSlashes)
}

export const isNumericProductIdRoute = (routePath: string) => {
  return /\/p\/[0-9]+\//.test(routePath)
}

export const getRouteParam = (
  params: RouteLocation['params'],
  key: string,
  defaultValue?: string,
) => {
  const value = params?.[key]

  if (Array.isArray(value)) {
    return value?.[0] ?? defaultValue
  }

  return value ?? defaultValue
}

export const getQueryParam = (
  query: RouteLocation['query'],
  key: string,
  defaultValue?: string,
) => {
  const regexp = /\[(\d+)?\]$/
  const [, indexRaw] = key.match(/\[(\d+)?\]$/) || []
  const index = parseInt(indexRaw, 10)
  const isArrayKey = regexp.test(key)

  const normalizedQuery: RouteLocation['query'] = {}
  for (const [k, v] of Object.entries(query)) {
    normalizedQuery[k.toLowerCase()] = v
  }

  const normalizedKey = key.toLowerCase()

  const value = isArrayKey
    ? normalizedQuery?.[normalizedKey] ||
      normalizedQuery?.[normalizedKey.replace(regexp, '[]')] ||
      normalizedQuery?.[normalizedKey.replace(regexp, '')]
    : normalizedQuery?.[normalizedKey] ||
      normalizedQuery?.[`${normalizedKey}[]`]

  if (Array.isArray(value)) {
    return value?.[index || 0] ?? defaultValue
  }

  if (value === null || value === undefined || index > 0) {
    return defaultValue
  }

  return value
}

export const removeQueryParam = (
  route: MaybeRefOrGetter<RouteLocation>,
  key: string,
) => {
  const url = new URL(toValue(route).fullPath, 'https://example.com')
  url.searchParams.delete(key)
  return url.toString().replace('https://example.com', '')
}

export const isPrivacyPage = (slug: string = '') =>
  PRIVACY_PAGE_SLUGS.includes(slug)

export { getCategoryPath }

export const getProductDetailRoute = (
  product: Product,
  query: Record<string, string | number | undefined> = {},
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  const pdpComponent = getPDPComponentType(product)
  const isGlasses =
    pdpComponent === PDPComponentType.glasses ||
    pdpComponent === PDPComponentType.correctionalGlasses
  const isContactLens = pdpComponent === PDPComponentType.contactLens
  const isCareProducts = pdpComponent === PDPComponentType.care
  const isMerch = pdpComponent === PDPComponentType.merch

  const normalizedQuery: Record<string, string | undefined> =
    Object.fromEntries(
      Object.entries(query).map(([key, value]) => {
        if (typeof value === 'number') {
          return [key, `${value}`]
        }
        return [key, value]
      }),
    )

  // Glasses and Merch should include variantId in URL only if user selects a variant
  // https://aboutyou.atlassian.net/browse/SCFIM-1031
  const variantsSortedByPrice = (
    product.variants ? [...(product?.variants ?? [])] : []
  ).sort((a, b) => a.price.withTax - b.price.withTax)

  // Add cheapest variantId automatically to URL for some categories
  if (
    !normalizedQuery.variantid &&
    !isGlasses &&
    !isMerch &&
    variantsSortedByPrice?.length
  ) {
    normalizedQuery.variantid = String(variantsSortedByPrice[0].id)
  }

  // Never include cheapest variantId in URL for other categories
  if (
    normalizedQuery.variantid &&
    (isGlasses || isMerch) &&
    variantsSortedByPrice?.length &&
    normalizedQuery.variantid === String(variantsSortedByPrice[0].id)
  ) {
    normalizedQuery.variantid = undefined
  }

  // variantIdL & variantIdR for contact lenses
  if (isContactLens) {
    normalizedQuery.variantid = undefined
  }

  const frameColorValues = product.attributes?.frameColor?.values as Value
  const manufacturerColorCodeValues = product.attributes?.manufacturerColorCode
    ?.values as Value
  const lensBaseColorValues = product.attributes?.lensBaseColor
    ?.values as Value[]
  const numberOfLensesValues = product.attributes?.numberOfLenses
    ?.values as Value

  const careProductVariant = product.variants?.find(
    (it) => it.id === Number(normalizedQuery.variantid),
  )

  const packingTypeDescription = careProductVariant?.attributes
    ?.packingTypeDescription?.values as Value

  const glassessAttributesSlug =
    isGlasses &&
    baseSlugify(
      [
        manufacturerColorCodeValues && manufacturerColorCodeValues?.value,
        frameColorValues && frameColorValues?.label,
        lensBaseColorValues && lensBaseColorValues[0]?.label,
      ].join('-'),
      slugifyOptions,
    )

  const contactLensSlug =
    isContactLens &&
    baseSlugify(
      (numberOfLensesValues && numberOfLensesValues?.value) ?? '',
      slugifyOptions,
    )

  const careProductsSlug =
    isCareProducts &&
    baseSlugify(
      (packingTypeDescription && packingTypeDescription.value) || '',
      slugifyOptions,
    )

  const slug = [
    baseSlugify(
      getFirstAttributeValue(product.attributes, 'name')?.label ?? '',
      slugifyOptions,
    ),
    glassessAttributesSlug,
    contactLensSlug,
    careProductsSlug,
    product.id,
  ].filter(Boolean)

  return {
    ...routeList.product,
    params: { slug: slug.join('-') },
    query: normalizedQuery,
  }
}

export const getSubscriptionProductDetailRoute = (
  product: SubscriptionProduct,
  query: Record<string, string | number | undefined> = {},
) => {
  const productData: Product = {
    id: product.productId,
    isActive: true,
    isSoldOut: false,
    isNew: false,
    createdAt: product.createdAt ?? '',
    updatedAt: product.updatedAt ?? '',
    images: [product.image],
  }

  return getProductDetailRoute(productData, {
    ...query,
  })
}

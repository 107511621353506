const useFormSubmitEvents = (
  track: (
    event: FimTrackingEvent,
    payload: FimTrackingPayload,
    options?: MapToTrackingPayloadOptions,
  ) => any,
) => {
  return {
    trackFormSubmit: (element: FimFormSubmitTrackingPayload['element']) => {
      const payload = {
        form: {
          identifier:
            'value' in element
              ? element?.dataset?.identifier ||
                element?.dataset?.testid ||
                element.id
              : element.identifier,
        },
      }

      track('FielmannBasic_FormSubmit', payload)
    },
  }
}

export default useFormSubmitEvents

export default defineNuxtPlugin({
  name: 'onetrust-script',
  setup() {
    const { $currentShop } = useNuxtApp()
    const route = useRoute()
    const dataDomainScript = $currentShop?.onetrustKey

    const slugs = route.fullPath?.split('/')?.filter(Boolean) ?? []

    if (
      !dataDomainScript ||
      Boolean(getQueryParam(route?.query, 'storeMode'))
    ) {
      return
    }

    // 'data-language' doesn't need to be set for single language environments like at/de
    // as there's only one language available in ontetrust

    useHead(() => ({
      script: [
        {
          body: true,
          async: true,
          src: '/js/consent-banner.js',
          key: 'one-trust-consent-banner',
          type: 'text/javascript',
          charset: 'UTF-8',
        },
        {
          src: 'https://fielmann.my.onetrust.eu/cdn/cookies/scripttemplates/otSDKStub.js',
          type: 'text/javascript',
          key: 'one-trust-otSDKStub',
          defer: true,
          body: true,
          charset: 'UTF-8',
          'data-domain-script': dataDomainScript,
        },
      ],
    }))
  },
})

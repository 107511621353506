export default defineNuxtRouteMiddleware((to) => {
  if (
    !to.path.endsWith('/') &&
    !/\.(css|js|pdf|jpe?g|png|webp|avif|webm|mp4|gif|ico)\/?$/.test(to.path)
  ) {
    const { path, query, hash } = to
    const nextPath = path + '/'
    const nextRoute = { path: nextPath, query, hash }
    return navigateTo(nextRoute, { redirectCode: 301 })
  }
})
